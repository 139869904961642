import React, { useMemo } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { Card, InputBase, Link, MenuItem, Select, Typography } from "@mui/material";
import {
  useMutationSaveOrderReceivable,
  useQueryOrderReceivableFormOptions
} from "../../api/OrderReceivable";
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridColDef
} from "@mui/x-data-grid-pro";
import useHealthChecker from "../../hooks/useHealthChecker";
import { format, isValid, parseISO } from "date-fns";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { debounce, get } from "lodash";
import { getGridOperatorsOnlyBy } from "../../utils/getGridStringOperatorsOnlyBy.ts";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { getGridSingleSelectMoreOperators } from "../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { DataGridToolbar } from "./DataGridToolbar.tsx";

const OrderReceivableList = () => {
  const { data: formOptions } = useQueryOrderReceivableFormOptions();

  useSetPageTitle("Receivables");

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: "reference_number",
        headerName: "Order #",
        width: 100,
        valueGetter: (value, row) => row.reference_number,
        filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"]),
        renderCell: params => {
          const { value, row } = params;
          return (
            <Link
              component={RouterLink}
              underline="hover"
              to={generatePath("/order/:uuid/finalized-quote", {
                uuid: row.uuid
              })}
            >
              {value}
            </Link>
          );
        }
      },
      {
        field: "order_type",
        headerName: "Order Type",
        valueGetter: (value, row, column) => get(row, column.field),
        type: "singleSelect",
        valueOptions: ["cod", "account"],
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "order_receivable.status",
        headerName: "Status",
        width: 200,
        renderCell: ({ value, row }) => {
          return <SelectStatusInput order_uuid={row.uuid} value={value} />;
        },
        valueGetter: (value, row, column) => get(row, column.field),
        type: "singleSelect",
        valueOptions: formOptions?.status,
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "order_receivable.assigned_to_user_id",
        headerName: "Assigned To",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <SelectAssignedToUserInput
              order_uuid={row.uuid}
              value={row?.order_receivable?.assigned_to_user?.uuid}
            />
          );
        },
        // for Sorting
        valueGetter: (value, row) => row?.order_receivable?.assigned_to_user?.full_name,
        type: "singleSelect",
        valueOptions: formOptions?.users?.map(user => ({ value: user.id, label: user.full_name })),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "customer.customer_group.id",
        headerName: "Customer Group",
        width: 150,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        valueGetter: (value, row) => row.customer?.customer_group?.name,
        type: "singleSelect",
        valueOptions: formOptions?.customer_groups?.map(customerGroup => {
          return {
            value: customerGroup.id,
            label: customerGroup.name
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "customer_id",
        headerName: "Customer",
        width: 180,
        renderCell: params => {
          const {
            row: { customer }
          } = params;
          if (customer) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/customers/:uuid/details", {
                  uuid: customer?.uuid
                })}
              >
                {customer.full_name_with_company}
              </Link>
            );
          }
        },
        type: "singleSelect",
        valueOptions: formOptions?.customers?.map((customer: any) => {
          return {
            value: customer.id,
            label: customer.full_name_with_company
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "is_invoiced_at",
        headerName: "Invoiced Date",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.is_invoiced_at),
        filterOperators: getGridDateOperators()
      },
      {
        field: "transactions_sum",
        headerName: "Receipts",
        width: 130,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },
      {
        field: "outstanding_amount",
        headerName: "Outstanding",
        width: 130,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        filterOperators: getGridNumericOperators()
      },
      {
        field: "order_receivable.detail",
        headerName: "Detail",
        sortable: false,
        width: 300,
        renderCell: ({ row }) => {
          return (
            <TextAreaDetailInput order_uuid={row.uuid} value={row?.order_receivable?.detail} />
          );
        }
      },
      {
        field: "order_receivable.updated_at",
        headerName: "Last Action At",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row, column) => parseISO(get(row, column.field)),
        filterOperators: getGridDateOperators()
      },
      {
        field: "customer.customer_type",
        headerName: "Account Type",
        width: 180,
        valueGetter: (value, row, column) => get(row, column.field),
        renderCell: params => {
          const {
            row: { customer }
          } = params;
          return (
            <Typography
              gutterBottom
              textTransform={customer?.customer_type === "cod" ? "uppercase" : "capitalize"}
            >
              {customer?.customer_type}
            </Typography>
          );
        },
        type: "singleSelect",
        valueOptions: ["cod", "account"],
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "statistics.invoiced_current.sum",
        headerName: "Current",
        width: 130,
        sortable: false,
        filterable: false,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        valueGetter: (value, row, column) => get(row, column.field)
      },
      {
        field: "statistics.invoiced_30_days.sum",
        headerName: "30 Days",
        width: 130,
        sortable: false,
        filterable: false,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        valueGetter: (value, row, column) => get(row, column.field)
      },
      {
        field: "statistics.invoiced_60_days.sum",
        headerName: "60 Days",
        width: 130,
        sortable: false,
        filterable: false,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        valueGetter: (value, row, column) => get(row, column.field)
      },
      {
        field: "statistics.invoiced_90_days.sum",
        headerName: "90 Days",
        width: 130,
        sortable: false,
        filterable: false,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        valueGetter: (value, row, column) => get(row, column.field)
      },
      {
        field: "statistics.invoiced_90_plus_days.sum",
        headerName: "Older",
        width: 130,
        sortable: false,
        filterable: false,
        valueFormatter: value => toDecimalFromFloat(value || 0),
        valueGetter: (value, row, column) => get(row, column.field)
      }
    ];
  }, [formOptions]);

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="order-receivable"
        autosizeOnMount={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              order_type: false
            }
          },
          sorting: {
            sortModel: [
              {
                field: "reference_number",
                sort: "desc"
              }
            ]
          }
        }}
        columns={columns}
        checkboxSelection={false}
        slots={{
          toolbar: DataGridToolbar
        }}
      />
    </Card>
  );
};

const SelectStatusInput = ({ order_uuid, value }: { order_uuid: string; value?: string }) => {
  const { data: formOptions } = useQueryOrderReceivableFormOptions();
  const { mutateAsync: saveOrderReceivable, isLoading: isLoadingSaveOrderReceivable } =
    useMutationSaveOrderReceivable();
  const { isSuspendMutations } = useHealthChecker();

  const handleSaveOrderReceivable = (order_uuid: string, property: string) => async (e: any) => {
    await saveOrderReceivable({
      order_uuid,
      [property]: e.target.value || null
    });
  };

  return (
    <Select
      fullWidth
      input={<InputBase />}
      disabled={isSuspendMutations || isLoadingSaveOrderReceivable}
      size="small"
      value={value ?? ""}
      onChange={handleSaveOrderReceivable(order_uuid, "status")}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {formOptions?.status?.map(name => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

const SelectAssignedToUserInput = ({
  order_uuid,
  value
}: {
  order_uuid: string;
  value?: string;
}) => {
  const { data: formOptions } = useQueryOrderReceivableFormOptions();
  const { mutateAsync: saveOrderReceivable, isLoading: isLoadingSaveOrderReceivable } =
    useMutationSaveOrderReceivable();
  const { isSuspendMutations } = useHealthChecker();

  const handleSaveOrderReceivable = (order_uuid: string, property: string) => async (e: any) => {
    await saveOrderReceivable({
      order_uuid,
      [property]: e.target.value || null
    });
  };

  return (
    <Select
      fullWidth
      input={<InputBase />}
      disabled={isSuspendMutations || isLoadingSaveOrderReceivable}
      size="small"
      value={value ?? ""}
      onChange={handleSaveOrderReceivable(order_uuid, "assigned_to_user_uuid")}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {formOptions?.users?.map(({ uuid, full_name }) => (
        <MenuItem key={uuid} value={uuid}>
          {full_name}
        </MenuItem>
      ))}
    </Select>
  );
};

const TextAreaDetailInput = ({ order_uuid, value }: { order_uuid: string; value?: string }) => {
  const { isSuspendMutations } = useHealthChecker();
  const { mutateAsync: saveOrderReceivable, isLoading: isLoadingSaveOrderReceivable } =
    useMutationSaveOrderReceivable();

  const handleChange = debounce((e: any) => {
    saveOrderReceivable({
      order_uuid,
      detail: e.target.value || null
    });
  }, 800);

  return (
    <InputBase
      disabled={isSuspendMutations || isLoadingSaveOrderReceivable}
      multiline
      rows={3}
      fullWidth
      onKeyDown={e => {
        e.stopPropagation();
      }}
      defaultValue={value ?? ""}
      onChange={handleChange}
    />
  );
};

export default OrderReceivableList;
