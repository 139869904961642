import React from "react";
import { Card } from "@mui/material";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { useOrderListColumns } from "../order/components/useOrderListColumns.tsx";
import { DataGridToolbar } from "../order/DataGridToolbar.tsx";
import { useQueryParamAvailability } from "../order/OrderList.tsx";

const OrderListWithQuery: React.VFC<{ query: any }> = ({ query }) => {
  const [availability] = useQueryParamAvailability();

  const columns = useOrderListColumns({ availability, isInCustomerDetailsPage: true });

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="order"
        autosizeOnMount={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              "customer.customer_group.id": false,
              created_at: true,
              is_invoiced_at: false,
              quote_accepted_at: false,
              active: false,
              deleted_at: false
            }
          },
          sorting: {
            sortModel: [
              {
                field: "reference_number",
                sort: "desc"
              }
            ]
          }
        }}
        columns={columns}
        checkboxSelection={false}
        slots={{
          toolbar: DataGridToolbar
        }}
        slotProps={{
          toolbar: {
            additionalQueryParams: { ...query, availability }
          }
        }}
        additionalQueryParams={{ ...query, availability }}
      />
    </Card>
  );
};

export default OrderListWithQuery;
