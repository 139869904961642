import { useMemo } from "react";
import { getGridOperatorsOnlyBy } from "../../../utils/getGridStringOperatorsOnlyBy.ts";
import { DateRangeOperator } from "../../../components/data-grid-v2/DateRangeOperator.tsx";
import { getGridSingleSelectMoreOperators } from "../../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { Can } from "../../../casl.ts";
import confirm from "../../../components/Confirm.tsx";
import { orderCopyConfirmationDialog } from "../OrderCopyDialog.tsx";
import { USER_ROLES } from "../../../constants.ts";
import { getGridDateOperators, getGridStringOperators, GridColDef } from "@mui/x-data-grid-pro";
import { Link, Menu, MenuItem } from "@mui/material";
import { generatePath, Link as RouterLink, useNavigate } from "react-router-dom";
import { format, isValid, parseISO } from "date-fns";
import {
  useMutationCopyOrder,
  useMutationDeleteOrder,
  useMutationOrderSetIsActive,
  useMutationRestoreDeletedOrder,
  useQueryOrderFormOptions
} from "../../../api/Order.ts";
import useAuth from "../../../hooks/useAuth.ts";
import useHealthChecker from "../../../hooks/useHealthChecker.ts";
import { useAuthAbility } from "../../../hooks/useAuthAbility.ts";
import { MoreVertical } from "react-feather";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

interface UseOrderListColumnsProps {
  availability: string | null; // "deleted" or "archived"
  isInCustomerDetailsPage?: boolean;
}

export const useOrderListColumns = ({
  availability,
  isInCustomerDetailsPage
}: UseOrderListColumnsProps) => {
  const { mutateAsync: deleteItem } = useMutationDeleteOrder();
  const { mutateAsync: restoreDeletedOrder } = useMutationRestoreDeletedOrder();
  const { mutateAsync: setIsActiveOrder } = useMutationOrderSetIsActive();
  const { mutateAsync: copyOrder } = useMutationCopyOrder();
  const navigate = useNavigate();
  const { checkRolesAccess } = useAuth();
  const { isSuspendMutations } = useHealthChecker();
  const ability = useAuthAbility();
  const { data: formOptions } = useQueryOrderFormOptions();

  return useMemo<GridColDef[]>(() => {
    return [
      {
        field: "reference_number",
        headerName: "Order #",
        width: 100,
        filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"]),
        renderCell: params => {
          const { value, row } = params;
          return (
            <Link
              component={RouterLink}
              underline="hover"
              to={generatePath(
                row?.is_quote_finalized ? "/order/:uuid/finalized-quote" : "/order/:uuid/edit",
                {
                  uuid: row.uuid
                }
              )}
            >
              {value}
            </Link>
          );
        }
      },
      {
        field: "created_at",
        headerName: "Quoted Date",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.created_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },
      {
        field: "quote_accepted_at",
        headerName: "Accepted Date",
        width: 180,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.quote_accepted_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },
      {
        field: "is_invoiced_at",
        headerName: "Invoiced Date",
        width: 180,
        filterable: !isInCustomerDetailsPage,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.is_invoiced_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },
      {
        field: "customer.customer_group.id",
        headerName: "Customer Group",
        width: 150,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        valueGetter: (value, row) => row.customer?.customer_group?.name,
        type: "singleSelect",
        valueOptions: formOptions?.customer_groups?.map((customerGroup: any) => {
          return {
            value: customerGroup.id,
            label: customerGroup.name
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "customer_id",
        headerName: "Customer",
        width: 180,
        filterable: !isInCustomerDetailsPage,
        renderCell: params => {
          const {
            row: { customer }
          } = params;
          if (customer) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/customers/:uuid/details", {
                  uuid: customer?.uuid
                })}
              >
                {customer.full_name_with_company}
              </Link>
            );
          }
        },
        type: "singleSelect",
        valueOptions: formOptions?.customers?.map((customer: any) => {
          return {
            value: customer.id,
            label: customer.full_name_with_company
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },

      {
        field: "lead.lead_number",
        headerName: "Lead",
        valueGetter: (value, row) => row.lead?.lead_number,
        renderCell: params => {
          const {
            value: leadNumber,
            row: { lead }
          } = params;

          if (ability.cannot("UpdateLead", "lead")) {
            return leadNumber;
          }

          if (lead) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/lead/:uuid/edit", {
                  uuid: lead?.uuid
                })}
              >
                {leadNumber}
              </Link>
            );
          }
        },
        filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"])
      },
      {
        field: "status_v2",
        headerName: "Status",
        width: 130,
        valueGetter: (value, row) => row?.status_v2?.toUpperCase(),
        renderCell: ({ value }) => value, // removing this will not display the cell value
        type: "singleSelect",
        valueOptions: formOptions?.order_status,
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "order_type",
        headerName: "Type",
        width: 130,
        filterable: !isInCustomerDetailsPage,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        type: "singleSelect",
        valueOptions: formOptions?.order_types,
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "sales_rep_id",
        headerName: "Sales Rep",
        width: 130,
        renderCell: params => {
          const {
            value: fullName,
            row: { sales_rep }
          } = params;

          if (ability.cannot("UpdateUser", "users")) {
            return fullName;
          }

          if (sales_rep) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/users/:uuid/edit", {
                  uuid: sales_rep?.uuid
                })}
              >
                {fullName}
              </Link>
            );
          }
        },
        // for sorting
        valueGetter: (value, row) => row?.sales_rep?.full_name,
        type: "singleSelect",
        valueOptions: formOptions?.sales_rep_users?.map((user: any) => ({
          value: user.id,
          label: user.full_name
        })),
        filterOperators: getGridSingleSelectMoreOperators()
      },

      {
        field: "notes_reference",
        headerName: "Reference",
        sortable: false,
        filterable: false,
        width: 200
      },

      {
        field: "sold_price",
        headerName: "Amount",
        valueFormatter: value => toDecimalFromFloat(value ?? 0),
        width: 120,
        type: "number"
      },

      {
        field: "discount",
        headerName: "Discount",
        valueGetter: (value, row) => {
          const { discount } = row;
          return `${discount ?? 0}%`;
        },
        width: 120,
        type: "number"
      },

      {
        field: "deleted_at",
        headerName: "Deleted Date",
        hideable: false,
        width: 180,
        type: "date",
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.deleted_at),
        filterOperators: [DateRangeOperator, ...getGridDateOperators()]
      },

      {
        field: "actions",
        headerName: "Actions",
        filterable: false,
        sortable: false,
        headerAlign: "right",
        align: "right",
        width: 100,

        renderCell: ({ row, api }) => {
          const { uuid, id } = row;
          return (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {popupState => (
                <>
                  <MoreVertical
                    fontSize="small"
                    style={{ color: "gray", cursor: "pointer" }}
                    {...bindTrigger(popupState)}
                  />
                  <Menu {...bindMenu(popupState)} autoFocus={false}>
                    {availability
                      ? [
                          <Can I="RestoreDeletedOrder" a="order" key="RestoreDeletedOrder">
                            <MenuItem
                              disabled={!row.deleted_at}
                              onClick={async () => {
                                if (
                                  await confirm({
                                    confirmation: "You are about to restore. Are you sure?",
                                    options: {
                                      title: "Confirm"
                                    }
                                  })
                                ) {
                                  popupState.close();
                                  api.updateRows([{ ...row, _action: "delete" }]);
                                  await restoreDeletedOrder(uuid);
                                }
                              }}
                            >
                              Restore deleted
                            </MenuItem>
                          </Can>,
                          <Can I="SetIsActive" a="order" key="SetIsActive">
                            <MenuItem
                              disabled={!!row.deleted_at}
                              onClick={async () => {
                                if (
                                  await confirm({
                                    confirmation: "You are about to Unarchive. Are you sure?",
                                    options: {
                                      title: "Confirm"
                                    }
                                  })
                                ) {
                                  popupState.close();
                                  api.updateRows([{ ...row, _action: "delete" }]);
                                  await setIsActiveOrder({
                                    uuid,
                                    active: true
                                  });
                                }
                              }}
                            >
                              Unarchive
                            </MenuItem>
                          </Can>
                        ]
                      : [
                          row?.is_quote_finalized && (
                            <Can I="SetIsActive" a="order" key={`set-is-active-${uuid}`}>
                              <MenuItem
                                onClick={async () => {
                                  if (
                                    await confirm({
                                      confirmation: "You are about to archive. Are you sure?",
                                      options: {
                                        title: "Confirm"
                                      }
                                    })
                                  ) {
                                    popupState.close();

                                    api.updateRows([{ ...row, _action: "delete" }]);
                                    await setIsActiveOrder({
                                      uuid,
                                      active: null
                                    });
                                  }
                                }}
                              >
                                Archive
                              </MenuItem>
                            </Can>
                          ),
                          <Can I="CopyOrder" a="order" key={`copy-${uuid}`}>
                            <MenuItem
                              onClick={async () => {
                                popupState.close();
                                const order_line_item_uuids = await orderCopyConfirmationDialog({
                                  order_id: id
                                });

                                if (order_line_item_uuids === false) {
                                  return;
                                }

                                const response = await copyOrder({
                                  uuid,
                                  order_line_item_uuids,
                                  order_type: "quote"
                                });
                                navigate(
                                  generatePath("/order/:uuid/edit", {
                                    uuid: response.copied_uuid
                                  })
                                );
                              }}
                            >
                              Create new copy
                            </MenuItem>
                          </Can>,

                          !row?.is_quote_finalized && (
                            <Can I="UpdateOrder" a="order" key={`edit-${uuid}`}>
                              <MenuItem
                                onClick={async () => {
                                  popupState.close();
                                  navigate(generatePath("/order/:uuid/edit", { uuid }));
                                }}
                              >
                                Edit
                              </MenuItem>
                            </Can>
                          ),

                          <Can I="DeleteOrder" a="order" key={`delete-${uuid}`}>
                            <MenuItem
                              onClick={async () => {
                                if (
                                  await confirm({
                                    confirmation: "You are about to delete. Are you sure?",
                                    options: {
                                      title: "Confirm"
                                    }
                                  })
                                ) {
                                  popupState.close();
                                  api.updateRows([{ ...row, _action: "delete" }]);
                                  await deleteItem(uuid);
                                }
                              }}
                              disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
                            >
                              Delete
                            </MenuItem>
                          </Can>
                        ]}
                  </Menu>
                </>
              )}
            </PopupState>
          );
        }
      }
    ];
  }, [
    ability,
    availability,
    checkRolesAccess,
    copyOrder,
    deleteItem,
    formOptions?.customer_groups,
    formOptions?.customers,
    formOptions?.order_status,
    formOptions?.order_types,
    formOptions?.sales_rep_users,
    isSuspendMutations,
    navigate,
    restoreDeletedOrder,
    setIsActiveOrder
  ]);
};
