import React from "react";
import { Card } from "@mui/material";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import { useLeadListColumns } from "./useLeadListColumns.tsx";
import { useQueryParamAvailability } from "../order/OrderList.tsx";

const LeadListWithQuery: React.VFC<{ query: any }> = ({ query }) => {
  const [availability] = useQueryParamAvailability();
  const columns = useLeadListColumns({ availability, isInCustomerDetailsPage: true });

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="lead"
        autosizeOnMount={false}
        columns={columns}
        checkboxSelection={false}
        slots={{
          toolbar: DataGridToolbar
        }}
        slotProps={{
          toolbar: {
            additionalQueryParams: { ...query }
          }
        }}
        additionalQueryParams={{ ...query }}
      />
    </Card>
  );
};

export default LeadListWithQuery;
