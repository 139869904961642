import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { ActionMap, AuthState, AuthUser, JWTContextType } from "../types/auth";

import axios from "../utils/axios";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { intersection } from "lodash";
import { USER_ROLES } from "../constants";
import { useAuthUserLoginEmailPassword, useAuthUserLogout, useQueryAuthUser } from "../api/Auth";
import { useLocalStorage } from "usehooks-ts";
import { AbilityContext } from "../casl";
import { AxiosError } from "axios";
import { openobserveRum } from "@openobserve/browser-rum";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const TOKEN_EXPIRED = "TOKEN_EXPIRED";
const SIGN_UP = "SIGN_UP";

interface AuthActionTypes {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [TOKEN_EXPIRED]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
  };
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case SIGN_OUT:
    case TOKEN_EXPIRED:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const queryClient = useQueryClient();
  const { closeSnackbar } = useSnackbar();
  const [accessToken, _setAccessToken] = useLocalStorage<string | null>("accessTokenV2", null);
  const {
    data: authUser,
    error,
    isLoading: isLoadingAuthUser,
    isError: isErrorAuthUser
  } = useQueryAuthUser(accessToken);
  const { mutateAsync: loginWithEmailPassword } = useAuthUserLoginEmailPassword();
  const { mutateAsync: logoutUser } = useAuthUserLogout();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    if (state.isAuthenticated && authUser) {
      openobserveRum.setUser(authUser);
    }
  }, [authUser, state.isAuthenticated]);

  useEffect(() => {
    if (isLoadingAuthUser) {
      return;
    }

    if (isErrorAuthUser) {
      const responseStatus = (error as AxiosError)?.response?.status ?? 500;
      if (responseStatus === 401) {
        delete axios.defaults.headers.common.Authorization;
        closeSnackbar();

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } else {
      ability.update(authUser?.permissions);

      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: true,
          user: authUser
        }
      });
    }
  }, [authUser, ability, accessToken, isLoadingAuthUser, isErrorAuthUser, closeSnackbar, error]);
  //
  // useEffect(() => {
  //   const responseStatus = (error as AxiosError)?.response?.status ?? 500;
  //   if (responseStatus === 401) {
  //     delete axios.defaults.headers.common.Authorization;
  //     closeSnackbar();
  //
  //     dispatch({
  //       type: INITIALIZE,
  //       payload: {
  //         isAuthenticated: false,
  //         user: null
  //       }
  //     });
  //   }
  // }, [closeSnackbar, error]);

  const setAccessToken = (accessToken: string | null) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }

    _setAccessToken(accessToken);
  };

  const signIn = async (email: string, password: string) => {
    const response = await loginWithEmailPassword({
      email,
      password
    });

    const { token, user } = response;

    queryClient.clear();
    setAccessToken(token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user
      }
    });
  };

  const signOut = async () => {
    await logoutUser({}); // intentionally left empty object for first arg
    localStorage.clear();
    openobserveRum.clearUser();
    openobserveRum.stopSession();
    closeSnackbar();
    setAccessToken(null);
    dispatch({ type: SIGN_OUT });
  };

  const tokenExpired = async () => dispatch({ type: TOKEN_EXPIRED });

  const signUp = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user
      }
    });
  };

  const checkRolesAccess = (allowedRoles?: string[]) => {
    const userRoles = state?.user?.roles ?? [];

    return (
      userRoles.includes(USER_ROLES.SUPER_ADMIN) ||
      intersection(allowedRoles ?? [], userRoles).length > 0
    );
  };

  const resetPassword = (email: string) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        checkRolesAccess,
        tokenExpired
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
